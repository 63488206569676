import React from 'react';

export type SubtitleSizes = 's1' | 's2' | 's3' | 's4' | 's5';

interface SubtitleTextProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  /**
   * Font size of the subtitle
   */
  size: SubtitleSizes;
  /**
   * Pass in additional tailwind classes.
   * To override font size you have to
   * set the size property to custom
   */
  additionalClasses?: string;
  /**
   * Text string for subtitle
   */
  children: React.ReactNode;
  /**
   * Center text horizontally
   */
  center?: boolean;
}

export const SubtitleText = ({
  center = false,
  size,
  additionalClasses,
  children,
  ...props
}: SubtitleTextProps) => {
  const isCenter = center ? 'text-center' : '';
  const classes = getClasses(size);

  return (
    <div
      className={[isCenter, `${classes}`, additionalClasses].join(' ')}
      {...props}
    >
      {children}
    </div>
  );

  /**
   * Gets the css classes for the subtitle size
   *
   * @param size - SubtitleSizes
   * @returns string of css classes
   */
  function getClasses(size?: SubtitleSizes): string {
    switch (size) {
      case 's2':
        return 'font-sohne text-black text-base leading-5';
      case 's3':
        return 'font-sohne text-gray-500 text-sm leading-5';
      case 's4':
        return 'font-sohne text-purple-500 text-xs leading-4';
      case 's5':
        return 'font-sohne font-bold text-purple-500 text-2xl leading-5';
      default:
        return 'font-sohne text-black text-lg leading-5';
    }
  }
};
