import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { EffectCallback, useEffect } from 'react';

interface GoogleTagManagerProps {
  userId?: string | null;
  subscription?: string;
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: Record<string, any>[];
  }
}

// Google Tag Manager - Global base code
export const GoogleTagManager = ({
  userId,
  subscription,
}: GoogleTagManagerProps) => {
  const googleTagManagerID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;
  const router = useRouter();
  const innerHtml = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${googleTagManagerID}');
  `;

  useEffect(handlePageView, [router.events, subscription, userId]);

  return (
    <Script
      id="gtag-base"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: innerHtml,
      }}
    />
  );

  /**
   * Sends router events to Google Tag Manager
   */
  function handlePageView(): ReturnType<EffectCallback> {
    router.events.on('routeChangeComplete', (url) => {
      GoogleTagManagerEvent('pageview', subscription, url, userId);
    });
    return () => {
      router.events.off('routeChangeComplete', (url) => {
        GoogleTagManagerEvent('pageview', subscription, url, userId);
      });
    };
  }
};

/**
 * Sends analytics to Google for events outside of page views
 *
 * @param event - The value that will appear as the event action in Google Analytics Event reports.
 * Look at the Google Tag Manager tag and align event names with them.
 * @param subscription - The label of the event.
 * @param url - The current page url.
 * @param user_id - The current signed in user.
 * @param event_category - The category of the event. Defaults to "general".
 * @param value - A non-negative integer that will appear as the event value. Defaults to zero.
 * @param transactionSubtotal - The transaction subtotal used by ShareASale.
 * @param transactionID - The transaction id usually payment intent id used by ShareASale.
 * @param couponcode - The coupon codes used as comma delimited string used by ShareASale.
 */
export const GoogleTagManagerEvent = (
  event:
    | 'AddToCart'
    | 'Book Preview Flow'
    | 'PurchaseBook'
    | 'PurchaseDigitalAsset'
    | 'PurchaseGift'
    | 'PurchaseSubscription'
    | 'Lead'
    | 'pageview'
    | 'RegistrationComplete',
  subscription: string | null | undefined,
  url: string,
  user_id?: string | null | undefined,
  event_category = 'general',
  value = 0,
  transactionSubtotal?: string,
  transactionID?: string,
  couponcode?: string,
  utm_campaign?: string | null,
  utm_source?: string | null,
  utm_content?: string | null,
) => {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'production') {
    window.dataLayer.push({
      event,
      event_category,
      subscription,
      page: url,
      user_id,
      value,
      transactionSubtotal,
      transactionID,
      couponcode,
      utm_campaign,
      utm_source,
      utm_content,
    });
  }
};
