import { NextRouter } from 'next/router';

/**
 * Navigates to the home page.
 *
 * @param router - Next router.
 * @param isMobile - If the source is 'mobile'
 */
export function navigateToHomePage(router: NextRouter, isMobile = false): void {
  if (isMobile) {
    document.location = 'co.qeepsake.qeepsake://qeepsake/m_dashboard';
    return;
  }
  router.push('/dashboard');
}
