import { NextRouter } from 'next/router';

/**
 * Go to the billing plans page either on web or mobile.
 *
 * @param router - next router
 * @param source - the source ShopQeeper was accessed from.
 */
export function goToBillingPlans(router: NextRouter, source: string | null) {
  // If we came from mobile, deeplink to the plans page
  if (source === 'mobile') {
    document.location = 'co.qeepsake.qeepsake://qeepsake/m_billing_page';
  } else {
    router.push('/settings/billing-plans');
  }
}
