/**
 * NOTE: This utility file exists in the codebase instead of being an npm package due to a limitation with npm registries.
 *
 * npm only allows one registry per scope (qeepsake). We have a private package (qutenberg-core) hosted on GitHub,
 * which prevents us from using a public package with the same scope on npm. As a temporary solution, we've extracted
 * the code from the npm package into this codebase, allowing us to use both the private qutenberg-core package
 * and this functionality without conflicts.
 *
 * This approach is not ideal and should be revisited in the future for a more sustainable solution.
 */

/**
 * Checks to see if the given string is a valid extracted Rails ID
 * i.e. 55587 and not gid://qeepsake-rails/JournalEntry/55587
 *
 * @param id - rails ID
 * @returns if ID is extracted rails ID
 */
export function isExtractedRailsId(id: string): boolean {
  if (typeof id === 'string') {
    // Extracted IDs should NOT start with gid:// with no empty spaces
    return !id.startsWith('gid://') && id.indexOf(' ') == -1;
  }

  return false;
}

/**
 * Extract rails ID
 *
 * If the ID is passed in the format of gid://qeepsake-rails/JournalEntry/55587
 * then it will be returned 55587
 *
 * @param gid - gid://qeepsake-rails/Model/55587
 * @param stripTimestamp - whether to strip the timestamp from the ID (i.e. %2C2022-09-26+13%3A08%3A27+UTC)
 *
 * @returns 55587
 */
export function extractRailsId(gid: string, stripTimestamp?: boolean): string;
export function extractRailsId(
  gid?: string | null,
  stripTimestamp?: boolean,
): string | null;
export function extractRailsId(
  gid?: string | null,
  stripTimestamp = false,
): string | null {
  // Checks if ID has already been extracted, if so, return it
  if (gid && isExtractedRailsId(gid)) return gid;

  if (typeof gid === 'string' && gid?.indexOf('/') != -1) {
    const gidComponents = gid.split('/');
    let extractedId = gidComponents[gidComponents.length - 1];

    // Strips the timestamp (%2C2022-09-26+13%3A08%3A27+UTC)) from the ID
    if (stripTimestamp && extractedId?.includes('%')) {
      extractedId = extractedId.split('%')[0];
    }

    if (extractedId) {
      return extractedId;
    }
  }

  return null;
}

/**
 * Checks if a given string is a valid Rails Global ID (gid).
 *
 * The function validates that the string conforms to the general Rails gid structure:
 * gid://<ANY_APP_NAME>/<MODEL_NAME>/<ID>
 *
 * @param str - The string to test.
 * @returns True if the string is a valid Rails gid, false otherwise.
 *
 * @example
 * isRailsId("gid://qeepsake-rails/User/1")  // returns true
 * isRailsId("gid://someotherapp/User/1")   // returns true
 * isRailsId("User/1")                      // returns false
 */
export function isRailsId(str: string): boolean {
  // eslint-disable-next-line no-useless-escape
  const pattern = /^gid:\/\/[^\/]+\/[a-zA-Z]+\/\d+$/;
  return pattern.test(str);
}
