import React from 'react';

export interface SideMenuOptionLabelBaseProps {
  children: React.ReactNode;
  additionalClasses?: string;
  'data-testid'?: string;
}
interface SideMenuOptionLabelPropsWithPath
  extends SideMenuOptionLabelBaseProps {
  path: string;
  'data-testid'?: string;
}

interface SideMenuOptionLabelPropsWithClick
  extends SideMenuOptionLabelBaseProps {
  onClick: () => void;
}

type SideMenuOptionLabel =
  | SideMenuOptionLabelPropsWithPath
  | SideMenuOptionLabelPropsWithClick;

export function SideMenuOptionLabel({
  children,
  additionalClasses,
  ...props
}: SideMenuOptionLabel): JSX.Element {
  const classes = `font-sohne text-black text-sm leading-5 mb-1 cursor-pointer hover:underline antialiased ${additionalClasses}`;
  if ('path' in props) {
    return (
      <a
        className={classes}
        href={props.path}
        data-testid={props['data-testid']}
      >
        {children}
      </a>
    );
  }

  return (
    <p
      className={classes}
      onClick={props.onClick}
      data-testid={props['data-testid']}
    >
      {children}
    </p>
  );
}
