/**
 * Returns if the active subscription is Premium or not
 *
 * @param activeSubscription - active subscription name.
 */
export function isPremiumPlan(
  activeSubscription: string | null | undefined,
): boolean {
  return activeSubscription === 'Qeepsake Premium';
}
