import React from 'react';
import { HeaderText } from './typography/header-text';

interface SideMenuSectionLabelProps {
  text: string;
}

export function SideMenuSectionLabel({
  text,
}: SideMenuSectionLabelProps): JSX.Element {
  return (
    <div className="flex justify-start items-center">
      <HeaderText size="h4" additionalClasses="mb-0 ml-2">
        {text}
      </HeaderText>
    </div>
  );
}
