import { errAsync, ResultAsync } from 'neverthrow';
import { NextRouter } from 'next/router';
import { Url } from 'url';
import { ErrorResult } from '../../configuration/error-result';

interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
}

/**
 * Navigate to page (this is to be used with neverthrow, usually .andThen) i.e.
 *
 * @example
 * doSomething().andThen(() =\> navigateToPage(router, '/some-page'))
 *
 * @param router - Next router
 * @param url - Url to navigate to (will throw error if url is undefined or null)
 * @param as - masks `url` for the browser
 * @param options - Options for navigating to page
 *
 * @returns result or error
 */
export function navigateToPage(
  router: NextRouter,
  url: string | undefined | null,
  as?: Url | undefined,
  options?: TransitionOptions | undefined,
): ResultAsync<
  boolean,
  ErrorResult<'NAVIGATE_TO_PAGE_FAILED' | 'NAVIGATE_TO_PAGE_URL_NOT_FOUND'>
> {
  if (url) {
    const pushFn = ResultAsync.fromPromise(router.push(url, as, options), (e) =>
      ErrorResult.create({
        name: 'NAVIGATE_TO_PAGE_FAILED',
        message: 'Failed to navigate to page',
        error: e,
        context: {
          extra: {
            url,
          },
        },
      }),
    );

    return pushFn;
  }

  const urlNotFoundError = ErrorResult.create({
    name: 'NAVIGATE_TO_PAGE_URL_NOT_FOUND',
    message: 'URL not found when trying to navigate to page',
    context: {
      extra: {
        url,
      },
    },
  });

  return errAsync(urlNotFoundError);
}
