import React, { useState } from 'react';
import { SideMenu } from '../../components/side-menu';
import { gql } from '@apollo/client';
import { FacebookPixel } from '../../components/facebook-pixel/facebook-pixel';
import { GoogleTagManager } from '../../components/google-tag-manager/google-tag-manager';
import { extractRailsId } from '../../utils/extract-rails-guid/extract-rails-guid';
import { useRouter } from 'next/router';
import { navigateToHomePage } from '../../utils/navigate-to-home-page/navigate-to-home-page';
import { navigateToPage } from '../../utils/navigate-to-page/navigate-to-page';
import { HeaderText } from '../../components/typography/header-text';
import { BodyText } from '../../components/typography/body-text';
import { Menu } from '@mui/icons-material';

export type NavigationType =
  | 'hamburger'
  | 'arrow'
  | 'cancel'
  | 'back'
  | 'done'
  | 'none';

interface HeaderProps {
  /**
   * Current user's information
   * If viewer is not defined the hamburger menu will be hidden
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  viewer: any | null | undefined;
  /**
   * Source of the incoming request
   */
  source: string | null;
  /**
   * Optionally overrides the header Qeepsake logo with string value
   */
  titleLabel?: string | null;
  /**
   * Type of navigation to display
   * Defaults to 'hamburger' if viewer is defined otherwise it defaults to 'none'
   */
  navigationType?: NavigationType | null;
  /**
   * Optional click event to handle the navigation if navigationLabel and navigationImage is provided.
   * Ignored if navigation is the hamburger menu since it's click even opens the side menu.
   * If navigation type is not 'hamburger' and no onNavigationClick event is defined it defaults to router.back()
   */
  onNavigationClick?: () => void;
}

export function Header({
  viewer,
  source,
  navigationType = 'hamburger',
  titleLabel,
  onNavigationClick,
}: HeaderProps): JSX.Element | null {
  const userId = viewer?.id;
  const activeOrder = viewer?.active_order;
  /** Hooks */
  const router = useRouter();
  const [toggleSideMenu, setToggleSideMenu] = useState(false);
  /** Variables */
  const lineItemsAmount = activeOrder?.order_item_count ?? 0;
  const hasLineItems = lineItemsAmount > 0;
  let subscription = '';
  if (viewer?.active_subscription) {
    subscription = viewer?.active_subscription.replace('Qeepsake', '');
  }
  const extractedUserId = extractRailsId(userId);
  const isMobile = source === 'mobile';
  const isWebview = source === 'webview';

  // If we are in a webview, we don't want to show the header
  if (isWebview) {
    return (
      <>
        <GoogleTagManager
          userId={extractedUserId}
          subscription={subscription}
        />
        <FacebookPixel />
      </>
    );
  }

  return (
    <>
      {viewer && (
        <SideMenu
          toggleSideMenu={toggleSideMenu}
          viewer={viewer}
          closeSideMenu={() => setToggleSideMenu(false)}
        />
      )}
      <div
        className="flex justify-between items-center bg-beige border-b-1 border-b-purple-500 p-5 xs:p-4"
        data-testid="header"
      >
        {/* Navigation */}
        <div className="w-8">{renderNavigation()}</div>

        {/* Logo or Title */}
        <div className="flex items-center">
          {titleLabel ? (
            <HeaderText size="h4" center={true} data-testid="headerLabel">
              {titleLabel}
            </HeaderText>
          ) : (
            <>
              <img
                src="/assets/logo/qeepsake-logo.png"
                alt="Logo of Qeepsake TM"
                width={120}
                className="cursor-pointer"
                onClick={() => navigateToHomePage(router)}
                data-testid="qeepsakeLogo"
              />
            </>
          )}
        </div>

        {/* Cart */}
        <div className="w-8">
          {extractedUserId && (
            <div
              className="flex flex-col items-center justify-center transform"
              onClick={() => navigateToPage(router, '/orders/cart')}
              data-testid="cartContainer"
            >
              {lineItemsAmount > 0 && (
                <div className="flex absolute items-center justify-center w-3 h-3 bg-purple-500 p-2 rounded-lg bottom-2.5 ml-1.5 cursor-pointer motion-safe:animate-bounce">
                  <span
                    className="text-xs text-center text-white-100"
                    data-testid="lineItemsCount"
                  >
                    {lineItemsAmount}
                  </span>
                </div>
              )}

              <img
                src="/assets/cart/cart.svg"
                width={23}
                height={20}
                alt="Shopping Cart"
                className={
                  'cursor-pointer' + (!hasLineItems ? ' hover:scale-110' : '')
                }
              />
            </div>
          )}
        </div>
      </div>

      <GoogleTagManager userId={extractedUserId} subscription={subscription} />
      <FacebookPixel />
    </>
  );

  /**
   * Renders a navigation type
   */
  function renderNavigation(): JSX.Element {
    // If the source is mobile always show the done navigationType
    const type = isMobile ? 'done' : navigationType;

    // If there is no viewer we cannot display the hamburger menu
    const showHamburgerMenu = viewer && type === 'hamburger';
    if (showHamburgerMenu) {
      return (
        <Menu
          className="text-purple-500 cursor-pointer transform hover:scale-110"
          onClick={() => setToggleSideMenu(!toggleSideMenu)}
          data-testid="menuIcon"
        />
      );
    }

    switch (type) {
      case 'arrow':
        return (
          <img
            src="../../assets/back-arrow.svg"
            alt="Back"
            className="ml-5 cursor-pointer"
            onClick={clickNavigationTextOrImage}
            data-testid="navigationImage"
          />
        );
      case 'back':
        return (
          <BodyText
            size="b2"
            additionalClasses="text-purple-500 cursor-pointer"
            onClick={clickNavigationTextOrImage}
            data-testid="backLink"
          >
            Back
          </BodyText>
        );
      case 'cancel':
        return (
          <BodyText
            size="b2"
            additionalClasses="text-purple-500 cursor-pointer"
            onClick={clickNavigationTextOrImage}
            data-testid="cancelLink"
          >
            Cancel
          </BodyText>
        );
      case 'done':
        return (
          <BodyText
            size="b2"
            additionalClasses="text-purple-500 cursor-pointer"
            onClick={clickNavigationTextOrImage}
            data-testid="doneLink"
          >
            Done
          </BodyText>
        );
      default:
        return <></>;
    }
  }

  /**
   * Handles all the navigation except the 'hamburger' navigationType
   */
  function clickNavigationTextOrImage(): void {
    if (onNavigationClick) {
      onNavigationClick();
    } else if (isMobile) {
      navigateToHomePage(router, true);
    } else {
      router.back();
    }
  }
}

Header.fragments = {
  viewer: gql`
    fragment Header on User {
      id
      active_order {
        id
        order_item_count
      }
      ...SideMenu_viewer
    }
    ${SideMenu.fragments.viewer}
  `,
};
