import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { SideMenuSectionLabel } from './side-menu-section-label';
import { gql } from '@apollo/client';
import { useSpring, animated } from '@react-spring/web';
import DayJs from 'dayjs';
import { InviteUrl_viewer } from '../graphql/generated/types';
import { extractRailsId } from '../utils/extract-rails-guid/extract-rails-guid';
import { SideMenuOptionLabel } from './side-menu-option-label';
import { logout } from '../utils/logout/logout';
import { goToBillingPlans } from '../utils/go-to-billing-plans/go-to-billing-plans';
import { useRouter } from 'next/router';
import { BookVoucherFragment } from '../fragments/book-voucher-fragment';
import { getQeepsakeHomepage } from '../utils/get-qeepsake-homepage/get-qeepsake-homepage';
import { HeaderText } from './typography/header-text';
import { SubtitleText } from './typography/subtitle-text';
import { Anchor } from './typography/anchor';
import { isPremiumPlan } from '../utils/is-premium-plan/is-premium-plan';
import { Add, CardGiftcard, ShoppingBag } from '@mui/icons-material';

export interface SideMenuProps {
  toggleSideMenu: boolean;
  viewer: InviteUrl_viewer;
  closeSideMenu: () => void;
}

export function SideMenu({
  toggleSideMenu: toggle,
  closeSideMenu: closeMenu,
  viewer,
}: SideMenuProps): JSX.Element {
  const router = useRouter();
  const journals = viewer.journals;
  const activeSubscription = viewer?.active_subscription;
  const onboardedAt = viewer.onboarded_at;
  const giftUrl = getQeepsakeHomepage('/gift');
  const isOnPaidPlan = viewer.is_on_paid_plan;
  const isPremium = isPremiumPlan(activeSubscription);
  const isContributor = viewer?.is_contributor;
  /** Hooks */
  const fadeAnimation = useSpring({ opacity: toggle ? 0.4 : 0 });
  const slideAnimation = useSpring({ left: toggle ? 0 : -250 });
  const memberSince = DayJs(onboardedAt).format('MMM YYYY');
  /** Links */
  const accountAndSettingsLink = '/settings/account-settings';
  const questionAndSettingsLink = '/settings/question-settings';
  const addJournalLink = '/journal/add-journal';
  const addContributorLink = '/settings/account-members/account-members';
  const recapEmailSettingsLink = '/settings/recap-recipients/recap-recipients';
  /** Variables */
  const onClickBillingAndPlans = () => goToBillingPlans(router, null);

  return (
    <div style={{ pointerEvents: toggle ? 'auto' : 'none' }}>
      <animated.div
        className="flex flex-col w-64 h-full fixed p-3 z-999 bg-purple-200 overflow-y-scroll"
        style={slideAnimation}
      >
        {/* ACCOUNT INFO */}
        <div
          className="border-900 border-b-0.5 pb-4 border-gray-500"
          data-testid="menuContainer"
        >
          <ClearIcon
            className="text-purple-500 cursor-pointer"
            onClick={() => closeMenu()}
            data-testid="closeMenuIcon"
          />
          <HeaderText
            size="h3"
            additionalClasses="mt-3 mb-1"
            data-testid="activeSubscription"
          >
            {activeSubscription}
          </HeaderText>

          {isOnPaidPlan ? (
            <SubtitleText size="s3" data-testid="membershipDate">
              Member since {memberSince}
            </SubtitleText>
          ) : (
            <Anchor
              text="Learn about membership plans"
              onClick={onClickBillingAndPlans}
              data-testid="learnAboutMembership"
            />
          )}
        </div>

        {/* SHOP */}
        <div className="flex flex-col border-purple-500 border-b-0.5 pt-3 pb-4">
          <SideMenuOptionLabel path={'/shop'} data-testid="orderBookLink">
            <div className="flex items-center">
              <ShoppingBag className="scale-75 text-purple-500" />
              Shop
            </div>
          </SideMenuOptionLabel>

          {giftUrl && (
            <SideMenuOptionLabel path={giftUrl} data-testid="giveGiftLink">
              <CardGiftcard className="scale-75 text-purple-500" /> Give
              Qeepsake
            </SideMenuOptionLabel>
          )}
        </div>

        {/* JOURNALS */}
        <div
          className="flex flex-col border-purple-500 border-b-0.5 pt-3 pb-4"
          data-testid="journalList"
        >
          <SideMenuSectionLabel text="JOURNALS" />
          {journals?.map(({ id, title }) => (
            <div key={id}>
              <SideMenuOptionLabel
                path={`/dashboard?id=${extractRailsId(id)}`}
                data-testid={`${title?.replace(/\s+/g, '')}-sideMenuLink`}
              >
                {title}
              </SideMenuOptionLabel>
            </div>
          ))}
          <SideMenuOptionLabel
            path={addJournalLink}
            data-testid="addJournalLink"
            additionalClasses="pt-2"
          >
            <Add className="scale-75 text-purple-500" />
            Add Journal
          </SideMenuOptionLabel>
        </div>

        {/* SETTINGS */}
        <div className="flex flex-col border-purple-500 border-b-0.5 pt-3 pb-4">
          <SideMenuSectionLabel text="SETTINGS" />
          <SideMenuOptionLabel
            path={accountAndSettingsLink}
            data-testid="accountSettingsLink"
          >
            Account Settings
          </SideMenuOptionLabel>
          <SideMenuOptionLabel
            path={questionAndSettingsLink}
            data-testid="questionSettingsLink"
          >
            Question Settings
          </SideMenuOptionLabel>

          {!isContributor && (
            <>
              {isPremium && (
                <SideMenuOptionLabel
                  path={addContributorLink}
                  data-testid="journalContributorsLink"
                >
                  Journal Contributors
                </SideMenuOptionLabel>
              )}
              <SideMenuOptionLabel
                path={recapEmailSettingsLink}
                data-testid="recapEmailSettingsLink"
              >
                Recap Email Settings
              </SideMenuOptionLabel>
            </>
          )}
        </div>

        {/* BILLING AND PLANS */}
        {!isContributor && (
          <div className="flex flex-col pt-3 pb-4">
            <SideMenuSectionLabel text="PLANS" />
            <SideMenuOptionLabel
              onClick={onClickBillingAndPlans}
              data-testid="billingPlansLink"
            >
              Billing & Plans
            </SideMenuOptionLabel>
          </div>
        )}

        <div className="flex flex-col mt-auto">
          <Anchor
            text="Help Center"
            href="https://help.qeepsake.com/hc/en-us"
            target="_blank"
            data-testid="helpCenterLink"
            additionalClasses="mb-2"
          />
          <Anchor
            text="Log Out"
            onClick={() => logout(router)}
            data-testid="logoutLink"
          />
        </div>
      </animated.div>
      <animated.div
        className="fixed w-full h-full opacity-40 visible bg-black z-50"
        style={fadeAnimation}
        onClick={() => closeMenu()}
      />
    </div>
  );
}

SideMenu.fragments = {
  viewer: gql`
    fragment SideMenu_viewer on User {
      active_subscription
      active_book_vouchers {
        ...BookVoucherFragment
      }
      onboarded_at
      id
      first_name
      last_name
      email
      journals {
        id
        title
      }
      links
      is_on_paid_plan
      is_contributor
    }
    ${BookVoucherFragment}
  `,
};
